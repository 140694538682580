<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The value of <stemble-latex content="$\Delta\text{H}^\circ$" /> for the reaction below is
        <stemble-latex content="-336 kJ." />
      </p>
      <p class="mb-5 pl-8">
        <chemical-latex content="CH4(g)  +  3 Cl2(g)  ->  CHCl3(l)  + 3 HCl(g)" />
      </p>
      <p>
        Calculate the heat released to the surroundings when
        <number-value :value="mass" unit="\text{g}" />
        of
        <stemble-latex content="$\text{HCl}$" />
        are formed from the reaction.
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\Delta\text{H}: $"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210_Task87',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
